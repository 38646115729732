.navbar {
    background-color: #f0f0f0;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
   
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #f0f0f0;
    width: 12vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    /* left:-100%; */
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
    margin-bottom: 10px;
}

.nav-text a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

/* .nav-text a:hover {
    background-color: #C3E6FF;
} */

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #f0f0f0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
}